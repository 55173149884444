import React, {Fragment} from 'react';
import "./style.css";
import Barbarian from './Entities/Barbarian Lv5.json'
import Paladin from './Entities/Paladin Lv5.json'

const SimParams = () => {
  const test_var = Barbarian
  console.log(test_var)
  return (
    <div>
      <p>Repetitions:</p>
      <form>
        <input type="textbox" id='repetitionsInput' />
      </form>
      <button id='runAnaButton'>Run Analysis</button>
      <button>Create New</button>
      <button>Enable Printing</button>
      <button>DM Mode</button>
      {/* <div id='test_var'>Hello Python</div> */}
      <div className="scrollable-div" id="test-output">Starting...</div>
      <script type="py" src="testScript.py"></script>
      {/* <script type="mpy" src="Dm_class.py" config='{"packages":["numpy"]}'></script> */}
      <script type="py" src="Simulator.py"></script>
      <script type="py" src="main.py">from Simulator import *</script>
      {/* <script type="py" src="main.py" config="pyscript.toml">from Dm_class import *</script> */}
      
      {/* <div dangerouslySetInnerHTML={{__html:
              `
              <py-script>
              from js import document
              from  pyodide.ffi import create_proxy
              from testScript import *
              
              def runPython(input):
              rep = int(Element('repetitionsInput').element.value)
              Element('test-output').element.innerText = 'Running Analysis:' + str(rep)
              function_proxy = create_proxy(runPython)
              document.getElementById("runAnaButton").addEventListener("click", function_proxy)
              
              text = ''
              for i in range(10):
              text += str(i)
              Element('test-output').element.innerText = text
              </py-script>
            `}}/> */}
    </div>
  )
}


export default SimParams


