import React from "react"

const Heroes = () => {
  return (
    <div>
      <button>Hero button</button>
      <button>+</button>
    </div>
  )
}

export default Heroes
